import React, { useState } from 'react'

export interface FAQ {
    question: string
    answer: string
}

interface FaqItemProps {
    question: string
    answer: string
}

const faqs: FAQ[] = [
    {
        question: 'What is a prom?',
        answer: 'A prom is a formal or semi-formal dance event, traditionally held for students at the end of the school year. It’s a night to dress up, dance, and make lasting memories. Ragtag Prom Night takes that nostalgic idea and gives it a fresh, creative edge.'
    },
    {
        question: 'What is Ragtag Prom Night?',
        answer: 'Ragtag Prom Night is a unique, laid-back celebration inspired by classic proms but with a modern, urban twist. Expect great music and a vibrant atmosphere where style and self-expression take center stage.'
    },
    {
        question: 'What is the dress code?',
        answer: 'Think effortless elegance with a personal touch. Whether it’s a sleek suit, a statement dress, or a creative mix of vintage and streetwear, come as your most confident and stylish self. The key is to be comfortable while looking sharp.'
    },
    {
        question: 'Can I bring a guest?',
        answer: 'Absolutely. At Ragtag, we believe the best nights are shared. Feel free to bring a friend or two and enjoy the experience together.'
    },
    {
        question: 'How do I get tickets?',
        answer: 'Grab your ticket at ragtagpromnight.peatix.com'
    },
    {
        question: 'Will there be food and drinks?',
        answer: 'Yes, a selection of drinks will be available at the venue. Please note that alcohol will only be served to guests who are 20 and over, in accordance with Japanese law.'
    },
    {
        question: 'Is there a theme for the night?',
        answer: 'The theme is inspired by classic proms but reimagined with a modern, artistic twist. Expect a mix of nostalgic and contemporary aesthetics, with a focus on self-expression and creativity.'
    },
    {
        question: 'Can I take photos at the event?',
        answer: 'Of course! We encourage capturing memories, but please be mindful of others’ privacy. There may also be an official event photographer documenting the night.'
    },
    {
        question: 'Will there be music and performances?',
        answer: 'Yes! Expect an eclectic mix of music throughout the night, from smooth beats to dancefloor favorites.'
    },
    {
        question: 'Is there an age limit?',
        answer: 'This event is open to guests 18 and older.'
    },
]

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <div className="rounded-lg p-6 border border-pink-500 bg-black/20">
            <div
                className="cursor-pointer flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="text-xl font-semibold mb-0 font-serif">
                    {question}
                </h3>
                <span className="text-2xl font-bold">
                    {isOpen ? '−' : '+'}
                </span>
            </div>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96 mt-4' : 'max-h-0'
                    }`}
            >
                <p>{answer}</p>
            </div>
        </div>
    )
}

const FAQSection: React.FC = () => {
    return (
        <div>
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-semibold mb-8 font-serif text-center">
                    FAQs
                </h1>
                <div className="space-y-6">
                    {faqs.map((faq, index) => (
                        <FaqItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQSection
