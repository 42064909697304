
import React, { useEffect, useRef, useState } from 'react'

import dj_ANNABEEZY from '../assets/artists/dj_ANNABEEZY.webp'
import dj_AliceYukiNekoMafia from '../assets/artists/dj_AliceYukiNekoMafia.webp'
import dj_Indigo from '../assets/artists/dj_Indigo.webp'
import dj_KentaImai from '../assets/artists/dj_KentaImai.webp'
import dj_Kiola from '../assets/artists/dj_Kiola.webp'
import dj_Meleetime from '../assets/artists/dj_Meleetime.webp'
import dj_Motherof2 from '../assets/artists/dj_Motherof2.webp'
import dj_PANICWORKS from '../assets/artists/dj_PANICWORKS.webp'
import dj_ROMderful from '../assets/artists/dj_ROMderful.webp'
import dj_Soft_Goth from '../assets/artists/dj_Soft_Goth.webp'
import dj_TAOMO from '../assets/artists/dj_TAOMO.webp'
import live_Lavance from '../assets/artists/live_Lavance.webp'
import live_Remora from '../assets/artists/live_Remora.webp'
import live_YurikoAbad from '../assets/artists/live_YurikoAbad.webp'
import live_Zulema from '../assets/artists/live_Zulema.webp'
import popup_HiOnFlowers from '../assets/artists/popup_HiOnFlowers.webp'
import popup_KhayHitomi from '../assets/artists/popup_KhayHitomi.webp'

// Artist Interface with category
interface Artist {
    name: string
    image?: string | null
    category: 'Live' | 'DJ' | 'Pop-up' | 'Dancers' | 'Flower Arrangement' | 'Tarot Reading' | 'Body Painting'
}

// Updated artist list with categories
const artists: Artist[] = [
    { name: 'Yuriko Abad', category: 'Live', image: live_YurikoAbad },
    { name: 'Zulema', category: 'Live', image: live_Zulema },
    { name: 'Remora', category: 'Live', image: live_Remora },
    { name: 'Lavance', category: 'Live', image: live_Lavance },
    { name: 'Kenta Imai', category: 'DJ', image: dj_KentaImai },
    { name: 'ROMderful', category: 'DJ', image: dj_ROMderful },
    { name: 'MELEETIME (FETCH)', category: 'DJ', image: dj_Meleetime },
    { name: 'Kiola', category: 'DJ', image: dj_Kiola },
    { name: 'Mother of 2', category: 'DJ', image: dj_Motherof2 },
    { name: 'AliceYuki (NEKO MAFIA)', category: 'DJ', image: dj_AliceYukiNekoMafia },
    { name: 'Indigo', category: 'DJ', image: dj_Indigo },
    { name: 'TAOMO', category: 'DJ', image: dj_TAOMO },
    { name: 'PANICWORKS', category: 'DJ', image: dj_PANICWORKS },
    { name: 'ANNABEEZYY b2b Sushi Potato', category: 'DJ', image: dj_ANNABEEZY },
    { name: 'Soft_Goth', category: 'DJ', image: dj_Soft_Goth },
    { name: 'Coasters Aobadai', category: 'Pop-up' },
    // { name: 'Lily Yurika', category: 'Dancers' },
    { name: 'Lina', category: 'Dancers' },
    { name: 'Hi on Flowers', category: 'Flower Arrangement', image: popup_HiOnFlowers },
    { name: 'Khay Hitomi', category: 'Tarot Reading', image: popup_KhayHitomi },
    { name: 'Kaye Intechnicolor', category: 'Body Painting' }
]

// Shuffle function to randomize the artist list
const shuffleArray = (array: Artist[]) => {
    return array.sort(() => Math.random() - 0.5);
}

// Badge colors for each category
const categoryColors: Record<Artist['category'], string> = {
    'Live': 'bg-red-500',
    'DJ': 'bg-blue-500',
    'Pop-up': 'bg-yellow-500',
    'Dancers': 'bg-green-500',
    'Flower Arrangement': 'bg-purple-500',
    'Tarot Reading': 'bg-pink-500',
    'Body Painting': 'bg-indigo-500'
}

// Reusable component for the artist's name and badge
const ArtistContent: React.FC<{ artist: Artist }> = ({ artist }) => (
    <div className="flex items-center justify-between">
        <h3 className="text-white text-xl">{artist.name}</h3>
        <span className={`inline-block ${categoryColors[artist.category]} text-white text-xs text-center font-bold px-2 py-1 rounded-xl`}>
            {artist.category}
        </span>
    </div >
)

// Reusable ArtistCard that uses ArtistContent
const ArtistCard: React.FC<{ artist: Artist }> = ({ artist }) => (
    <div className="max-w-100 rounded-xl shadow-xl bg-black/30 overflow-hidden">
        {artist.image ? (
            <div className="relative">
                <img
                    src={artist.image}
                    alt={artist.name}
                    className="w-100 h-56 object-cover"
                />
                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-[#d55656] to-transparent p-4">
                    <ArtistContent artist={artist} />
                </div>
            </div>
        ) : (
            <div className="h-full bg-gradient-to-t from-[#d55656] to-transparent p-4">
                <ArtistContent artist={artist} />
            </div>
        )}
    </div>
)

// Reusable grid component for displaying a list of artist cards
const ArtistGrid: React.FC<{ artists: Artist[] }> = ({
    artists,
}) => (
    <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {artists.map((artist, index) => (
                <ArtistCard key={index} artist={artist} />
            ))}
        </div>
    </div>
)

const FeaturedArtistsSection: React.FC = () => {
    const [showAll, setShowAll] = useState(false)
    const extraArtistsRef = useRef<HTMLDivElement>(null)
    const [extraHeight, setExtraHeight] = useState(0)


    const [randomizedArtists, setRandomizedArtists] = useState<Artist[]>([])

    // Shuffle only once on initial render
    useEffect(() => {
        setRandomizedArtists(shuffleArray(artists))
    }, [])


    const visibleArtists = randomizedArtists.slice(0, 6)
    const extraArtists = randomizedArtists.slice(6)

    useEffect(() => {
        if (extraArtistsRef.current) {
            setExtraHeight(extraArtistsRef.current.scrollHeight)
        }
    }, [showAll, extraArtists.length])

    return (
        <div className=" mx-auto">
            <p className="text-4xl font-semibold mb-8 font-serif text-center">
                Featured Artists
            </p>
            {/* Always visible artists */}
            <ArtistGrid artists={visibleArtists} />

            {/* Extra artists with expandable animation */}
            {extraArtists.length > 0 && (
                <div
                    ref={extraArtistsRef}
                    style={{
                        maxHeight: showAll ? extraHeight : 0,
                        overflow: 'hidden',
                        transition: 'max-height 0.5s ease',
                    }}
                    className="mt-6 mx-0 px-0 mx-0"
                >
                    <ArtistGrid artists={extraArtists} />
                </div>
            )
            }

            {/* Toggle Button */}
            {
                extraArtists.length > 0 && !showAll && (
                    <div className="mt-4 flex justify-center">
                        <button
                            onClick={() => setShowAll(!showAll)}
                            className="px-4 py-2 bg-black/20 text-white rounded-xl hover:bg-yellow-700 transition"
                        >
                            See all artist
                        </button>
                    </div>
                )
            }
        </div >
    )
}

export default FeaturedArtistsSection