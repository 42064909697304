import React from 'react'
import heroVideo from '../assets/herovideo.webm'
import heroVidPlaceholder from '../assets/herovidplaceholder.png'

const HeroSection: React.FC = () => {
    return (
        <section className="relative h-screen w-full overflow-hidden text-white font-sans bg-black">
            <video
                className="absolute inset-0 w-full h-full object-cover blur-sm"
                autoPlay
                loop
                muted
                playsInline
                poster={heroVidPlaceholder}
            >
                <source src={heroVideo} type="video/webm" />
            </video>

            {/* Optional overlay */}
            <div className="absolute inset-0 bg-black/44" />

            <div className="relative z-10 flex flex-col items-center justify-center text-center w-full h-full px-4">
                <p className="text-7xl font-bold mb-2 font-serif drop-shadow-xl">
                    PROM NIGHT
                </p>
                <p className="text-lg mt-2 font-serif drop-shadow-xl">
                    March 8, Tokyo
                </p>
                <div className="absolute bottom-24 flex flex-col items-center drop-shadow-xl">
                    <span className="text-white text-2xl animate-bounce drop-shadow-xl">↓</span>
                    <span className="text-white text-sm mt-2 drop-shadow-xl">Scroll</span>
                </div>
            </div>
        </section>
    )
}

export default HeroSection
