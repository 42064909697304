import React from 'react'
import { Calendar3, GeoAlt } from 'react-bootstrap-icons'

export interface EventDetail {
    icon: React.ReactNode
    title: string
    subtitle: string
}

const eventDetails: EventDetail[] = [
    {
        icon: <Calendar3 size={48} />,
        title: 'March 8, Saturday',
        subtitle: '9:00 PM - Late'
    },
    {
        icon: <GeoAlt size={48} />,
        title: 'S Club',
        subtitle: 'Shibuya, Tokyo'
    }
]

const EventDetailsSection: React.FC = () => {
    return (
        <div>
            <div className="py-8 md:px-16 flex justify-center">
                <div className="max-w-xl mx-auto justify-center md:space-x-8 md:space-y-0 space-y-1">
                    {eventDetails.map((detail, index) => (
                        <div key={index} className="flex items-center space-x-4">
                            <div className="h-16 w-16 flex items-center justify-center">
                                {detail.icon}
                            </div>
                            <div className="flex flex-col h-20 justify-center">
                                <p className="text-xl font-bold">{detail.title}</p>
                                <p className="text-sm">{detail.subtitle}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <section id="get-tickets" className="py-4 px-4 md:px-16">
                <div className="max-w-4xl mx-auto text-center">
                    <p className="text-4xl font-semibold mb-6 font-serif">
                        Ragtag Prom Night
                    </p>
                    <p className="text-lg leading-relaxed">
                        Get your best suits and dresses. RAGTAG is back!


                        Join us to relive prom, where romance meets nightlife. Dress to impress and step into an evening held by Tokyo’s largest event team.


                        Whether you’re here to dance, listen to music, meet people, or enjoy the local art and popups, this event exemplifies local Tokyo nightlife.
                    </p>
                </div>
            </section>
        </div>
    )
}

export default EventDetailsSection