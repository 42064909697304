import React from 'react';

const onGetTickets = () => {
    window.open('https://ragtagpromnight.peatix.com/', '_blank', 'noopener,noreferrer');
};

const GetTicketsButton: React.FC = () => {
    return (
        <button
            onClick={onGetTickets}
            className="
                rounded-xl
                py-4
                px-6
                font-semibold
                text-white
                shadow-lg
                bg-gradient-to-r
                from-[#ff6f61]
                to-[#d4145a]
                transition-all
                duration-300
                ease-in-out
                animate-gradient-pulse
            "
        >
            GET TICKETS NOW
        </button>
    );
};

export default GetTicketsButton;