import React from 'react';
import { goToInstagram } from '../hooks/goToInstagram';


const InstagramButton: React.FC = () => {
    return (
        <button
            onClick={goToInstagram}
            className="
            rounded-xl
            py-4
            px-4
            font-semibold
            text-white
            shadow
            bg-gradient-to-l
            from-orange-400
            to-[#cd2f96]
            hover:opacity-90
        "
        >
            Visit Ragtag on Instagram
        </button>
    );
};

export default InstagramButton;