import React, { useState } from 'react'

import HeroSection from '../components/HeroSection'
import EventDetailsSection from '../components/EventDetailsSection'
import FAQSection from '../components/FAQSection'
import FeaturedArtistsSection from '../components/FeaturedArtistsSection'
import VenueInfo from '../components/VenueInfo'
import poster from '../assets/promposter.jpg'
import GetTicketsButton from '../components/GetTicketsButton'
import InstagramButton from '../components/InstagramButton'

const RagtagPromLandingPage: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
        setIsDrawerOpen(false)
    }

    return (
        <>
            {/* Fixed Menu Button (toggles drawer) */}
            <button
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                className="fixed top-4 right-4 z-50 w-12 h-12 bg-gray-800 rounded-xl flex items-center justify-center"
            >
                {isDrawerOpen ? (
                    <svg
                        className="w-6 h-6 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                ) : (
                    <svg
                        className="w-6 h-6 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16M4 18h16"
                        ></path>
                    </svg>
                )}
            </button>

            {/* Overlay to blur content and close drawer on click */}
            {isDrawerOpen && (
                <div
                    onClick={() => setIsDrawerOpen(false)}
                    className="fixed inset-0 z-39 bg-black/20 backdrop-blur-sm"
                />
            )}

            {/* Drawer Menu */}
            <div
                className={`fixed top-0 right-0 h-full bg-gray-900 text-right text-white z-40 transform shadow-xl transition-transform duration-300 ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'}`}
                style={{ width: '80%' }}
            >
                <nav className="h-full">
                    <ul className="flex flex-col justify-evenly h-full mx-12 text-4xl font-serif">
                        <li>
                            <button onClick={() => scrollToSection('event-details')}>
                                ABOUT&nbsp;PROM
                            </button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('get-tickets')}>
                                TICKETS
                            </button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('artists')}>
                                ARTISTS
                            </button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('venue')}>
                                VENUE
                            </button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('faq')}>
                                FAQs
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Page Content */}
            <HeroSection />

            <div className="gradient-background font-sans text-white pb-32">
                <section id="event-details">
                    <EventDetailsSection />
                </section>

                <section className="py-6 md:px-16 text-center">
                    <GetTicketsButton />
                </section>

                <section className="flex justify-center py-8">
                    <div className="rounded-xl shadow-xl w-60 overflow-hidden">
                        <img src={poster} alt="Prom Poster" className="w-full h-auto" />
                    </div>
                </section>

                <section id="artists" className="py-12 px-4">
                    <FeaturedArtistsSection />
                </section>

                <section id="venue" className="py-12 px-4 md:px-16">
                    <VenueInfo />
                </section>

                <section className="py-6 px-4 md:px-16">
                    <div className="max-w-3xl mx-auto text-center">
                        <InstagramButton />
                    </div>
                </section>

                <section id="faq" className="py-12 px-4 md:px-16">
                    <FAQSection />
                </section>

                <section className="py-6 px-4 md:px-16 text-center">
                    <GetTicketsButton />
                </section>
            </div>
        </>
    )
}

export default RagtagPromLandingPage